import { format } from "date-fns"

import { useTranslate } from "src/i18n/useTranslate"
import { DateFieldDropdown } from "src/ui/DateRangePicker/DateFieldDropdown"
import { getDatePickerTimeFormat } from "src/ui/DateRangePicker/datePickerLogic"
import { TDateRange } from "src/ui/DateRangePicker/DateRangePicker"
import {
  DateRangePickerContainer,
  DateRangePickerContainerProps,
} from "src/ui/DateRangePicker/DateRangePickerContainer"

export type DateRangeDropdownProps = {
  dateRange: TDateRange
  setDateRange: (dateRange: TDateRange) => void
  label?: React.ReactNode
  required?: boolean
  dateRangePickerContainerProps?: Partial<DateRangePickerContainerProps>
}

export function DateRangeDropdown({
  dateRange,
  setDateRange,
  label,
  required,
  dateRangePickerContainerProps,
}: DateRangeDropdownProps) {
  const { t, langKeys } = useTranslate()

  const dropdownLabel = label ?? t(langKeys.date)
  return (
    <DateRangePickerContainer
      {...dateRangePickerContainerProps}
      startDate={dateRange.startDate}
      endDate={dateRange.endDate}
      onDateRangeChange={({ startDate, endDate }: TDateRange) => {
        setDateRange({ startDate, endDate })
      }}
      defaultPresetKey="MONTH"
      behavior="dateRange"
      renderButton={({ onClick, state }) => {
        return (
          <DateFieldDropdown
            onClick={onClick}
            displayValue={getDispalyValue(state.startDate, state.endDate)}
            label={dropdownLabel}
            required={required}
          />
        )
      }}
    ></DateRangePickerContainer>
  )
}

function getDispalyValue(startDate: Date | null, endDate: Date | null) {
  const formatType = getDatePickerTimeFormat(startDate, endDate)
  const start = startDate ? format(startDate, formatType) : ""
  const end = endDate ? format(endDate, formatType) : ""
  const dateRangeStr = start && end ? `${start} - ${end}` : ``
  return dateRangeStr
}
